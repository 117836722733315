.funfacts-container {
    margin: 120px 0;

}

.funfacts {
    max-width: 1200px;
    margin: 50px auto 0 auto;


}

// .funfacts h1 {
//     font-size: 60px;
//     margin-left: 10px;
//     color: $blacktitle;
// }
.jumbotron {
    position: relative;
    min-height: 600px;
    background-image: url('./../../assets/bg4.jpg');
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    min-height: 600px;
    width: 100%;
    z-index: 0;

}

.jumbotron-cover-overlay {
    background-color: rgba(38, 70, 83, 0.6);
    min-height: 600px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.jumbotron-cover-legend {

    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-shadow: 1px 1px #333;
    line-height: 1.25;

    @media (max-width: 450px) {
        width: 90%;
    }


}

.jumbotron__legend {


    &--name {
        font-size: 60px;
        color: $white;
        font-weight: 600;
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
    }

    &--experience {
        font-size: 35px;
        margin: 20px 0 0 0;
        color: $white;
        font-weight: 600;
    }

    &--legacy {
        font-size: 25px;
        color: $white;
        font-weight: 600;
    }

}
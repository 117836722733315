* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.App {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    height: 100vh;
    ;
}
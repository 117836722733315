.main-content {
    margin: 0 auto;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 9999999;
    color: #ffff;
}

.menyNav {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}
.main-header {
    position: absolute;
    display: block !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0;
    z-index: 3;
    position: relative;
    color: #fff;
    font-weight: 600;
    background: transparent;
    width: 100%;
}

.header .header-logo {
    margin: 0 15px;
}

.menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .menu-item {
        margin: 0 15px;
    }
}



.main-header-wrapper {
    position: relative;
    z-index: 6;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 200px;

    .main-header-title {
        font-size: 50px;
        font-weight: 900;
        color: #fff;
    }

    .main-header-legend {
        font-weight: 500;
        color: #fff;
    }
}
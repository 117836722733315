.carousell-container {
    background: #F8F9FC;
    margin: 120px 0 0 0;
}

.custom-carousell {
    max-width: 1200px;
    margin: 50px auto 0 auto;
    text-align: center;

    h1 {
        margin-left: 10px;
        color: #356277;
    }

    p {
        margin-left: 15px;
        margin-right: 15px;
        color: #666;
    }

}

.casousell-text {
    position: absolute;
    top: 0;
    left: 50px;
    z-index: 9999;
}

.carousell-overlay {
    position: absolute;
    width: 100%;
    height: 250px;
    background: #000;
    z-index: 9999;
}

.alice-carousel__stage-item * {
    text-align: center;

}
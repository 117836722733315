.skills {
    max-width: 1200px;
    margin: 50px auto 0 auto;


    h2 {
        padding: 20px 0;
    }

    p {
        line-height: 30px;
    }
}

.list_ExperienceStudies {
    li {
        color: #163643;
        text-decoration: underline;
        list-style: none;
        padding: 5px 10px;

    }
}
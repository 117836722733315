@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');



@import './variables';
@import './body';
@import './aboutme';
@import './header';
@import './jumbotron';
@import './main-content';
@import './skills';
@import './carousell';
@import './funfacts';
@import './footer';
@import './experienceStudies';